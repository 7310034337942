import { ref } from "vue";

export function useFscModal() {
  const modals = ref<{ [key: string]: any }>({});

  const registerModal = (name: string, modalRef: any) => {
    modals.value[name] = modalRef;
  };

  const showModal = (name: string) => {
    if (modals.value[name]) {
      modals.value[name].show();
    }
  };

  const hideModal = (name: string) => {
    if (modals.value[name]) {
      modals.value[name].hide();
    }
  };

  return {
    registerModal,
    showModal,
    hideModal,
  };
}
