
import { Options, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { namespace } from "s-vuex-class";

import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import HintButton from "@/components/HintButton.vue";
import FavoriteButton from "@/components/FavoriteButton.vue";
import QuestionHint from "@/components/QuestionHint.vue";

import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";
import FavoriteActionMixin from "@/mixins/FavoriteActionMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const TheoryQuestionsModule = namespace("theory-questions");
const StudentEducationModule = namespace("student-education");
const StudentQuestionAnswersModule = namespace("student-question-answers");

@Options({
  components: {
    Question,
    HintButton,
    FavoriteButton,
    QuestionHint,
  },
})
export default class SubcategoryQuestions extends mixins(QuestionAnswersMixin, FavoriteActionMixin) {
  @Prop({ required: true }) questionIds!: number[];
  @Prop({ required: true }) selectedQuestionId!: number;

  @TheoryQuestionsModule.Action("findOne")
  findQuestionAction!: (options: ICrudOptions) => Promise<void>;

  @StudentEducationModule.Action("withTheory/findAll")
  findAllStudentEducationWithTheory!: (options: ICrudOptions) => Promise<void>;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction!: any;

  @TheoryQuestionsModule.Getter("getDataItem")
  getQuestion!: any;

  @TheoryQuestionsModule.Getter("getIsLoading")
  loadingQuestion!: boolean;

  @StudentEducationModule.Getter("withTheory/getDataList")
  studentEducations!: any[];

  @StudentEducationModule.Getter("withTheory/getIsLoading")
  studentEducationsLoading!: boolean;

  public question: any = {};
  public studentEducation = 0;
  private showSolutionButton = false;
  public showCorrectAnswers = false;
  private selectedQuestionIndex = 0;

  mounted() {
    this.loadStudentEducations();
  }

  private async loadStudentEducations(): Promise<void> {
    await this.findAllStudentEducationWithTheory({
      resource: "/student-educations/with-theory/student/current",
      descriptionField: "",
    });

    if (this.studentEducations?.length > 0) {
      this.studentEducation = this.studentEducations[0].id;
      this.selectedQuestionIndex = this.questionIds.findIndex((id) => id === this.selectedQuestionId);
      this.loadQuestionById(this.selectedQuestionId);
    }
  }

  private loadQuestionById(id: number): void {
    this.findQuestionAction({
      resource: "theory-questions",
      id,
      hideSuccessMessage: true,
      descriptionField: "",
      params: { studentEducationId: this.studentEducation },
    }).then(() => {
      this.question = { ...this.getQuestion };

      // Scroll to nav buttons on mobile devices
      this.$nextTick(() => {
        if (window.innerWidth <= 768) {
          setTimeout(() => {
            const el = this.$refs.navButtonsRef as HTMLElement;
            if (el?.scrollIntoView) {
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }, 100); // small delay to ensure full layout
        }
      });
    });
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  private goToNext(): void {
    if (this.selectedQuestionIndex < this.questionIds.length - 1) {
      this.selectedQuestionIndex++;
      this.showCorrectAnswers = false;
      this.loadQuestionById(this.questionIds[this.selectedQuestionIndex]);
    }
  }

  private goToPrev(): void {
    if (this.selectedQuestionIndex > 0) {
      this.selectedQuestionIndex--;
      this.showCorrectAnswers = false;
      this.loadQuestionById(this.questionIds[this.selectedQuestionIndex]);
    }
  }
}
